import React, { Component, Suspense } from 'react';
import { IonApp, IonRouterOutlet, IonContent } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Switch, Redirect, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { USER_POOL } from './services/url.constant';
import '../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "./hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';

import Amplify, { Auth } from 'aws-amplify';
Amplify.configure({
  Auth: USER_POOL
});

const SignIn = Loadable({
  loader: () => import('./pages/auth/SignIn'),
  loading: Loader
});

const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader
});

const ForgotPassword = Loadable({
  loader: () => import('./pages/auth/ForgotPassword'),
  loading: Loader
});

const ForgotPasswordConfirm = Loadable({
  loader: () => import('./pages/auth/ForgotPasswordConfirm'),
  loading: Loader
});

class App extends Component {

  render() {

    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              <IonApp>
                <IonContent fullscreen scrollEvents={true}>
                  <Route path="/login" component={SignIn} exact={true} />
                  <Route path="/forgot-password" component={ForgotPassword} exact={true} />
                  <Route path="/forgot-password-confirm/:username" component={ForgotPasswordConfirm} exact={true} />
                  <Route path="/home" component={AdminLayout} />
                  <Route exact path="/" render={() => <Redirect to="/login" />} />
                </IonContent>
              </IonApp>
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Aux>
    );
  }
}

export default App;
