export default {
    defaultPath: '/home/dashboard',
    basename: '/fbc', // only at build time to set
    layout: 'vertical', // vertical, horizontal
    collapseMenu: false, // mini-menu
    layoutType: 'menu-dark', // menu-dark, menu-light
    headerBackColor: 'header-blue', // header-blue, header-red, header-purple, header-info, header-dark
    fullWidthLayout: true,
    navFixedLayout: true,
    headerFixedLayout: true
};
